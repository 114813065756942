import {
  SlateEditor,
  SlateElement,
  SlateTransforms,
  DomEditor,
  SlateNode,
} from "@wangeditor/editor";
import {
  TEXTAREA_EDIT_KEY,
  TEXTAREA_EDIT_LOADING,
  TEXTAREA_EDIT_TYPE,
  TEXTAREA_EDIT_TIP,
  EDITOR_HOVER_BAR_KEY,
  EDITOR_VISUAL_INPUT_KEY,
  EDITOR_CONTAINER_KEY,
  EDITOR_STATUS,
} from "@/views/markdown/config/wange";
import ee from "event-emitter";
import { parseHtmlString, parseTextsToBlocks } from "./marked";
import scrollIntoView from "scroll-into-view-if-needed";
// import Styler from 'stylefire';
// import { animate } from 'popmotion';

/**
 * 注：
 *  由于editor组件使用了 change  处理，onfocus 在编辑过程中不会一直触发
 *  如果不走 change ，需要手动 blue， 这样对用户体验来说不是特别友好
 *  直接走 change 则要避免手动使用 select 方法
 *
 * event:
 *  selectionChange ...
 *  cursorChange ...
 *  execCommand ...
 */

class MarkdownEditor {
  static e = null;
  // 自定义解析文本结构
  // {
  //   docTitle: false // 是否是当前文档标题
  //   textType: '' // title: 标题 list: 列表 context: 纯文本
  //   htmlFlag: '' // dom 标签名
  //   innerHTML: '' // 节点对应的 dom 展示内容
  //   ordered?: false // list 是否为有序列表
  //   previousSymbol?: '' // list 节点试图展示前置符号
  //   level: 1 // list 节点层级
  //   type:  // WangEditor.Node.type wangeditor 自带节点的类型属性
  //   children: // WangEditor.node.children wangeditor 自带节点的子项属性
  // }
  static textBlocks = [];

  static editorState = {
    allText: "",
    contextChange: false,
    longPress: false, // 是否长按
    paragraph: "", // 段落
    selectionText: "", // 选中文本
    selection: null, // 选区内容
    longPressSelection: null, // 长按选区内容
  };

  /**
   * event-emitter on
   */
  static on() {
    console.log("MarkdownEditor on");
  }

  /**
   * event-emitter emit
   */
  static emit() {
    console.log("MarkdownEditor off");
  }

  /**
   * event-emitter off
   */
  static off() {
    console.log("MarkdownEditor off");
  }

  static destroyed() {
    this.e?.destroy?.();

    this.e = null;
    this.textBlocks = [];
  }

  // TODO: 更新变更
  static updateChange() {
    this.editorState.contextChange =
      this.editorState.allText !== this.e.getText();
    if (this.editorState.contextChange) {
      this.emit("contextChange", true);
    }
  }

  // 更新光标信息
  static updateCursor() {
    if (this.e.selection) {
      // 记录更新的选区
      this.editorState.selection = JSON.parse(JSON.stringify(this.e.selection));
      const { focus, anchor } = this.editorState.selection;
      const cursor = JSON.stringify(focus) === JSON.stringify(anchor);

      console.log("cursor", cursor);

      /**
       * cursor: 编辑器的光标, false 为长按, true 为单击光标
       * 如果只有光标, 则裁剪从光标到文段的字符串, 用于 aigc 操作
       * 在任意情况下, 存储长按 (cursor 为 true) 选区内容和单击光标选区内容
       * 用于在 aigc 做 高亮回显 - restoreSelection
       */

      this.editorState.longPress = !cursor;

      if (cursor) {
        const currentNode = this.getCursorNode(focus.path);

        // TODO: 如果 文本内容中有穿插 特殊的文本，可能需要处理一次。例如图片什么的
        if (currentNode?.text) {
          this.editorState.selectionText = currentNode.text.substring(
            0,
            focus.offset
          );
        } else {
          this.editorState.selectionText = "";
        }

        this.emit("cursorChange", { cursor: true, longPress: false });
      } else {
        this.editorState.selectionText = this.e.getSelectionText();
        this.editorState.longPressSelection = JSON.parse(
          JSON.stringify(this.e.selection)
        );
        this.emit("cursorChange", { cursor: false, longPress: true });
      }
    } else {
      this.emit("cursorChange", { cursor: false, longPress: false });
    }
  }

  // 展示 hoverbar
  static toggleHideHoverBar(visible) {
    const $hoverbar = document.querySelector(`.${EDITOR_HOVER_BAR_KEY}`);

    if (visible) {
      $hoverbar?.classList.remove("hide");
    } else {
      $hoverbar?.classList.add("hide");
    }
  }

  // 查询选中段落节点
  static getSelectedNodeByType(type = "paragraph") {
    try {
      if (!this.editorState.selection) {
        return;
      }

      const node = DomEditor.getSelectedNodeByType(this.e, type);
      return [DomEditor.toDOMNode(this.e, node), node];
    } catch (e) {
      console.error("getSelectedNodeByType", e);
    }

    return [];
  }

  // 检测流媒体节点类型 image | video
  static testMediaNode() {
    // 由于 wangeditor 在插入过的时候。会追加一些换行的节点信息，对于空内容的节点需要过滤一次
    // 非空节点并且存在了其它节点类型，则类型检测错误
    if (this.e.isEmpty()) {
      return false;
    }

    const isImage = this.getSelectedNodeByType("image").length > 0;
    const isVideo = this.getSelectedNodeByType("video").length > 0;

    return isVideo || isImage;
  }

  // 查找尾部 dom 节点
  static getLastNode() {
    const node = this.e.children[this.e.children.length - 1];

    if (!node) {
      return;
    }

    return DomEditor.toDOMNode(this.e, node);
  }

  // 获取悬浮弹窗实例
  static getHoverBar() {
    return DomEditor.getHoverbar(this.e);
  }

  // 获取当前段落文本
  static getCursorParagraphText() {
    if (!this.editorState.selection) {
      return "";
    }

    const node = this.getCursorNode(this.editorState.selection.focus.path);
    return node?.text || "";
  }

  // 获取当前光标节点
  static getCursorNode(at) {
    try {
      return SlateEditor.node(this.e, at)[0];
    } catch (e) {
      console.error("getCursorNode", at, e);
    }

    return null;
  }

  // 获取当前编辑节点
  static getCurrentEditNode() {
    return document.querySelector(`.${TEXTAREA_EDIT_KEY}`);
  }

  // 获取当前编辑内容
  static getCurrentEditNodeText() {
    const html = this.getCurrentEditNode()?.innerHTML || "";
    const parser = new DOMParser().parseFromString(html, "text/html");
    parser.body.querySelector(`#${TEXTAREA_EDIT_TIP}`).remove();
    return parser.body.innerText;
  }

  // 是否选中在尾部
  static isSelectionAtLineEnd(path) {
    if (this.e.isEmpty()) {
      return true;
    }

    const selection = this.editorState.selection;
    const isAtLineEnd =
      SlateEditor.isEnd(this.e, selection.anchor, path) ||
      SlateEditor.isEnd(this.e, selection.focus, path);
    return isAtLineEnd;
  }

  // 移除当前编辑节点动画效果
  static removeEditNodeLoading() {
    const $currentEditNode = this.getCurrentEditNode();
    if (!$currentEditNode) {
      return;
    }

    const $loading = $currentEditNode.querySelector(
      `.${TEXTAREA_EDIT_LOADING}`
    );

    $loading?.parentNode?.remove();
  }

  // 在当前编辑节点插入提示词
  static togglePlaceholderInEditNode(visible) {
    const $currentEditNode = this.getCurrentEditNode();
    if (!$currentEditNode) {
      return;
    }

    const $loading = $currentEditNode.querySelector(
      `.${TEXTAREA_EDIT_LOADING}`
    );

    if (!$loading) {
      return;
    }

    if (visible) {
      $currentEditNode.children[0].innerHTML = `<span style="color: #c8c9cc;">*** 请输入你想提问的问题 ***</span>`;
      $currentEditNode
        .querySelector(`.${TEXTAREA_EDIT_LOADING}`)
        ?.parentNode?.remove();
    } else {
      $currentEditNode.children[0].innerHTML = "";
    }
  }

  // 插入文段
  static insertText(text) {
    try {
      this.removeEditNode();

      // 将光标偏移到最后面, 再插入文本，避免被替换
      const anchorCursor = this.editorState.selection?.anchor;
      const focusCursor = this.editorState.selection?.focus;
      const lastPath = Math.max(
        anchorCursor?.path[0] || 0,
        focusCursor?.path[0] || 0
      );
      const lastCursor = Math.max(
        anchorCursor?.offset || 0,
        focusCursor?.offset || 0
      );

      this.e.select({
        anchor: {
          path: [lastPath, 0],
          offset: lastCursor,
        },
        focus: {
          path: [lastPath, 0],
          offset: lastCursor,
        },
      });

      this.e.insertText(text);
    } catch (e) {
      console.error("insertText error", e);
    }
  }

  // 替换文段
  static replaceText(text) {
    try {
      this.removeEditNode();

      this.e.select(this.editorState.selection);
      this.e.insertText(text);
    } catch (e) {
      console.error("replaceText error", e);
    }
  }

  // 插入节点
  static insertNodes() {
    try {
      if (this.textBlocks.length < 1) {
        return;
      }

      this.removeEditNode();
      const paragraphs = this.textBlocks;

      console.log("insertNodes paragraphs", paragraphs.length, paragraphs);

      // 生成的结果
      //  存在分段：先将第一段按段内容插入，然后其余节点按段插入
      //  不存在段：将段转换为文本插入（注：如果文本超长也不处理，超长文本还是会有问题）
      if (paragraphs.length > 1) {
        // 空的时候，直接插入, 非空走分段处理
        if (this.emptyWithoutEditNode()) {
          SlateTransforms.insertNodes(this.e, paragraphs, { at: [0] });
        } else {
          const firstParagraph = paragraphs.shift();
          const text = firstParagraph.children[0].text;

          this.insertText(text);
          SlateTransforms.insertNodes(this.e, paragraphs);
        }
      } else if (paragraphs.length === 1) {
        paragraphs[0].children.forEach((node) => {
          this.insertText(node.text);
        });
      }
    } catch (e) {
      console.error("insertNodes error", e);
    }
  }

  // 替换节点
  static replaceNodes() {
    try {
      if (this.textBlocks.length < 1) {
        return;
      }

      this.removeEditNode(this.e);
      const paragraphs = this.textBlocks;

      console.log("replaceNodes paragraphs", paragraphs);

      // 生成结果
      //  存在分段：先将选中的文本用第一个节点替换掉，然后将剩余节点按段插入
      //  不存在分段：直接将选中文本替换
      if (paragraphs.length > 1) {
        // const firstParagraph = paragraphs.shift();
        // const text = firstParagraph.children[0].text;
        // this.replaceText(text);

        SlateTransforms.insertNodes(this.e, paragraphs);
      } else if (paragraphs.length === 1) {
        // 存在多段子节点 (节点内容有高亮或其它的) 的情况下，先将第一段替换，然后后续的文段直接插入
        const node = paragraphs[0].children.shift();

        if (node) {
          this.replaceText(node.text);
        }

        paragraphs[0].children.forEach((node) => {
          this.e.insertText(node.text);
        });
      }
    } catch (e) {
      console.error("replaceNodes error", e);
    }
  }

  // 插入编辑节点
  static insertEditNode() {
    const node = { type: TEXTAREA_EDIT_TYPE, children: [{ text: "" }] };

    this.removeEditNode(this.e);

    if (this.e.isEmpty()) {
      SlateTransforms.insertNodes(this.e, node, { at: [0, 0] });
    } else {
      // 存在跨端选区，只获取最后 selection 的光标位置，并在该位置插入编辑节点
      const anchorCursor = this.editorState.selection?.anchor?.path[0] || 0;
      const focusCursor = this.editorState.selection?.focus?.path[0] || 0;
      const lastCursor = Math.max(anchorCursor, focusCursor);

      console.log("cursor", anchorCursor, focusCursor, lastCursor);

      SlateTransforms.insertNodes(this.e, node, {
        at: [lastCursor + 1],
      });
    }
  }

  // 删除当前插入的编辑节点
  static removeEditNode() {
    const $currentEditNode = this.getCurrentEditNode();
    if (!$currentEditNode) {
      return;
    }

    let editNodeIndex = 0;

    try {
      editNodeIndex = this.e.children.findIndex(
        (item) => item.type === TEXTAREA_EDIT_TYPE
      );

      // fix: wangeditor clear 和 setHtml 的默认结构不一致
      if (editNodeIndex !== -1) {
        console.log("非标准结构对应的下标", editNodeIndex);
        SlateTransforms.removeNodes(this.e, { at: [editNodeIndex] });
      } else {
        for (let i = 0, size = this.e.children.length; i < size; i++) {
          editNodeIndex = this.e.children[i].children.findIndex(
            (node) => node.type === TEXTAREA_EDIT_TYPE
          );
          if (editNodeIndex !== -1) {
            console.log("标准结构对应的下标", editNodeIndex, i);
            SlateTransforms.removeNodes(this.e, { at: [editNodeIndex] });
            break;
          }
        }
      }

      // 如果没有节点了，要构建一个默认初始化节点，api 设计存在问题...
      const initialEditorValue = [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ];

      if (this.e.children.length === 0) {
        SlateTransforms.insertNodes(this.e, initialEditorValue);
      }
    } catch (e) {
      console.error("removeEditNode error", e, this.e.children);
      throw e;
    }
  }

  // 转义当前编辑节点的文本内容
  static transformEditNodeContext(context) {
    const $currentEditNode = this.getCurrentEditNode();
    if (!$currentEditNode) {
      return;
    }

    if (context.length < 1) {
      return;
    }

    context = context.replace(/!\[([^\]]+)\]\(([^)]+)\)/g, "");

    // 备用 过滤 markdown 代码块
    this.textBlocks = parseTextsToBlocks(
      context
        .split(RegExp("\n+"))
        .map((text) => text.trimStart())
        .filter((x) => x.trim())
    );
    const fragment = document.createDocumentFragment();

    // 解析目录：标题（h1 - h5）、列表（li）
    for (const textBlock of this.textBlocks) {
      if (textBlock.textType === "title") {
        const $header = document.createElement(textBlock.htmlFlag);
        $header.innerHTML = textBlock.innerHTML;
        fragment.append($header);
      } else if (textBlock.textType === "list") {
        // 这里不能用 ol、li，无序列表在源代码中使用的是 genPreSymbol 的处理方式...
        const $list = document.createElement("div");
        $list.innerHTML = textBlock.innerHTML;
        $list.style.margin = textBlock.style.margin;
        fragment.append($list);
      } else {
        const $text = document.createElement("div");
        $text.innerHTML = textBlock.innerHTML;
        fragment.append($text);
      }
    }

    $currentEditNode.children[0].remove();
    $currentEditNode.append(fragment);
  }

  // 滚动到编辑节点
  static scrollIntoEditNode() {
    const $currentEditNode = this.getCurrentEditNode();
    if (!$currentEditNode) {
      return;
    }

    scrollIntoView($currentEditNode.children[1], {
      scrollMode: "if-needed",
      block: "center",
      inline: "nearest",
      behavior: "smooth",

      // skipOverflowHiddenElements: true,
    });
  }

  // 设置节点属性
  static setNodeProp(opType, props = {}) {
    if (opType === "align") {
      // 对齐只对非内联的元素生效
      SlateTransforms.setNodes(this.e, props, {
        match: (n) => SlateElement.isElement(n) && !SlateEditor.isInline(n),
      });
    } else if (opType === "list") {
      if (props.type === "paragraph") {
        SlateTransforms.setNodes(this.e, {
          type: "paragraph",
          ordered: undefined,
          level: undefined,
        });
      } else {
        SlateTransforms.setNodes(this.e, {
          type: "list-item",
          ordered: props.ordered,
          indent: undefined,
        });
      }
    } else {
      SlateTransforms.setNodes(this.e, props);
    }
  }

  // 获取选中区域节点标记属性
  static getSelectionMark(markAll = false) {
    let mark = {};
    try {
      mark = SlateEditor.marks(this.e) || {};
      if (markAll) {
        // 追加获取居中、列表、标题等属性, 跨内容多选的时候，可能需要处理一下
        const nodeEntries = SlateEditor.nodes(this.e, {
          match: (node) => {
            const type = DomEditor.getNodeType(node);
            if (type === "paragraph") return true;
            if (type === "list-item") return true;
            if (type.match("header")) return true;

            return false;
          },
          universal: true,
          mode: "highest",
        });

        for (let nodeEntry of nodeEntries) {
          const [node] = nodeEntry;

          if (!node) {
            continue;
          }

          if (node.textAlign) {
            mark[node.textAlign] = true;
          }
          if (node.type === "list-item") {
            mark[node.ordered ? "orderList" : "unOrderList"] = true;
          }
          if (node.type.match("header")) {
            mark[node.type] = true;
          }
        }
      }

      return mark;
    } catch (e) {
      console.warn("getSelectionMark", e);
    }

    return mark;
  }

  // 获取选中标记配置项
  static updateSelectionMark(key, value = undefined) {
    if (!this.e) {
      return;
    }

    if (value === undefined) {
      const mark = this.getSelectionMark();
      this.e.addMark(key, !mark[key]);
    } else {
      this.e.addMark(key, value);
    }
  }

  // 清除选中节点标记属性
  static clearSelectionAllMark() {
    // wangeditor\packages\custom-types.d.ts
    // 清除 type CustomText
    const keys = [
      // StyledText
      "bold",
      "code",
      "italic",
      "through",
      "underline",
      "sup",
      "sub",
      // FontSizeAndFamilyText
      "fontSize",
      "fontFamily",
      // ColorText
      "color",
      "bgColor",
    ];

    for (const key of keys) {
      this.e.removeMark(key);
    }
  }

  // 重写 wangeditor 还原选区 packages\core\src\editor\plugins\with-selection.ts
  // 如果回显不了，请查看项目中是否有其它地方自动使用了聚焦的功能
  // 全局搜一下 focus 查看一下问题，一般生成结果回显不了，都是抢光标导致的。
  static restoreSelection() {
    // 任意情况下都只有选区，不存在光标的情况
    if (!this.editorState.selection) {
      return;
    }

    const hasSelectionText = this.editorState.selectionText.length > 0;
    const isEmpty = this.emptyWithoutEditNode();
    const { focus, anchor } = this.editorState.selection;
    // 光标在前面，反选
    const offset = focus.offset > anchor.offset ? focus.offset : anchor.offset;

    console.log(
      `longPress: ${this.editorState.longPress}, isEmpty: ${isEmpty}, hasSelectionText: ${hasSelectionText}`
    );

    // 长按直接高亮，否则取光标到初始位置的地方进行高亮
    if (this.editorState.longPress) {
      // fix: 高亮抖动
      this.e.focus(true);
      SlateTransforms.select(this.e, this.editorState.longPressSelection);
    } else if (!isEmpty && hasSelectionText) {
      this.e.focus();
      SlateTransforms.select(this.e, {
        focus: {
          path: focus.path,
          offset,
        },
        anchor: {
          // 段落节点中有修饰的内容节点，二维需要置 0
          path: [anchor.path[0], 0],
          offset: 0,
        },
      });
    } else {
      this.callingVisualKeyboard();
    }
  }

  // 呼叫键盘
  static callingVisualKeyboard() {
    const $visualInput = document.querySelector(`.${EDITOR_VISUAL_INPUT_KEY}`);

    if ($visualInput) {
      $visualInput.focus();
    } else {
      const $input = document.createElement("input");
      $input.type = "textarea";
      $input.classList.add(EDITOR_VISUAL_INPUT_KEY);
      document.body.append($input);
      $input.focus();
    }
  }

  // 判断当前编辑器是否为空
  static emptyWithoutEditNode() {
    if (this.e.isEmpty()) {
      return true;
    }

    // 这里的结构不稳定，不跟 isEmpty 一同判断，插入节点阶段实际上也是一种空状态
    if (this.e.children?.[0]?.children?.length === 1) {
      return this.e.children[0].children[0].type === TEXTAREA_EDIT_TYPE;
    }

    return false;
  }

  // 提取 目录结构
  static html2Tree() {
    return parseHtmlString(this.e);
  }
}

ee(MarkdownEditor);

export { MarkdownEditor };
