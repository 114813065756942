<template>
  <div class="miniProgramCode">
    <van-popup
      @opened="onOpened"
      v-model="showDialog"
      @closed="onClose"
      class="code"
    >
      <div class="content">
        <div class="notes">微信扫码<br />即可在小程序查看</div>

        <div class="QRCode" ref="qrcode_box"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Cookies from 'js-cookie'
import QRCode from 'qrcodejs2'
export default {
  name: 'miniProgramCode',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      show: false,
      loading: false,
      showDialog: false,
      codeImg: '',

      // 参数
      query: '',
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async onOpened() {
      new QRCode(this.$refs.qrcode_box, {
        text: `https://mall.writemall.com/code?url=${this.url}`,
        width: 180,
        height: 180,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: 3,
      })
    },

    // 关闭清除数据
    onClose() {
      this.show = false
      this.url = ''
      this.$refs.qrcode_box.innerHTML = ''
    },

    /**
     * url /pages/index/index type:String
     *
     * params type:String || Object
     * id=123&obj=JSON.stringify(obj)
     */
    openMiNiProgramCode(url, params) {
      const token =
        JSON.parse(localStorage.getItem('auth_token')) ||
        Cookies.get('auth_token')
      console.log(token)
      if (params) {
        this.url = url + `&${this.montage(params)}&token=${token}`
      } else {
        this.url = url + `&token=${token}`
      }
      this.show = true
      this.showDialog = true
    },

    montage(data) {
      if (typeof data !== 'object') return params
      let str = ''
      let list = Object.keys(data)
      list.forEach((el, i) => {
        if (i === list.length - 1) {
          if (typeof data !== 'object') {
            str += `${el}=${data[el]}`
          } else {
            str += `${el}=${data[el]}`
          }
        } else {
          if (typeof data !== 'object') {
            str += `${el}=${data[el]}&`
          } else {
            str += `${el}=${data[el]}&`
          }
        }
      })
      return str
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    window.openMiNiProgramCode = this.openMiNiProgramCode
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.miniProgramCode {
  ::v-deep .van-popup {
    padding: 20px;
    border-radius: 10px;
    .notes {
      font-size: 14px;
      color: #999;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 10px;

      text-align: center;
    }
  }
}
</style>
