import HeaderModule, { OPTIONS } from "./header";
import { Boot } from "@wangeditor/editor";

export function registerModule() {
  Boot.registerModule(HeaderModule);
}

export const CONSTANTS = {
  HEADER: OPTIONS,
};
