import { h } from "snabbdom";
import { OPTIONS } from "./constants";

function genRenderElem(level) {
  function renderHeader(el, children, editor) {
    const renderOption = OPTIONS.find((option) => option.value === el.type);

    const wrapper = h(`h${level}`, {
      style: {
        fontWeight: '400',
        fontSize: renderOption.renderEl.fontSize
      }
    }, [])

    // 标题比较特殊，要做成和 wps 的标题加粗效果一样，可以对标题进行加粗和
    // 去除加粗效果。如果没有标记加粗效果，则默认走加粗，否则默认按标记了加粗的来进行加粗
    for (let i = 0, size = el.children.length; i < size; i++) {
      const unBold = el.children[i].bold === false

      if (unBold) {
        wrapper.children.push(children[i])
      } else {
        wrapper.children.push(
          h('strong', children[i])
        )
      }
    }

    return wrapper;
  }

  return renderHeader;
}

const getHeaderOrder = (order) => {
  return `header${order}`;
};

const renderHeader1Conf = {
  type: getHeaderOrder(1),
  renderElem: genRenderElem(1),
};
const renderHeader2Conf = {
  type: getHeaderOrder(2),
  renderElem: genRenderElem(2),
};
const renderHeader3Conf = {
  type: getHeaderOrder(3),
  renderElem: genRenderElem(3),
};
const renderHeader4Conf = {
  type: getHeaderOrder(4),
  renderElem: genRenderElem(4),
};
const renderHeader5Conf = {
  type: getHeaderOrder(5),
  renderElem: genRenderElem(5),
};

export {
  renderHeader1Conf,
  renderHeader2Conf,
  renderHeader3Conf,
  renderHeader4Conf,
  renderHeader5Conf,
};
