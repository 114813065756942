const canvas = {
  namespaced: true,
  state: {
    frontImage: null,
    backImage: null,
    activeObject: null,
    hasShopStore: false,
    hasCard: false,
    electronCardType: 1, // 1. 名片 2. 礼品卡 3. 优惠券
    frontCard: {
      name: "辛巴达的名单哈",
      headImg:
        "https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/miniapps/wqzk/ca51f9f6-a8e8-4d23-b523-404523d8c1e8-1719629721305.jpg",
      position: "CEO",
      workUnit: "某某科技有限公司",
      mobile: "1353****963",
      email: "ehd*****m68@0355.net",
      detailAddress: "四川省都江堰市*********元2304室",
      bgimgId: 42,
      bgimgStyle: 0,
      bgimg:
        "https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/analysis-others/c1263916-5eae-4ac5-bbb5-60a57fd5d6cd.png",
      briefIntroduction: "",
      layoutId: 2,
    },
    backCard: {},
  },
  mutations: {
    SET_THUMBNAIL(state, payload) {
      state.frontImage = payload.frontImage;
      state.backImage = payload.backImage;
    },
    SET_ACTIVE_OBJECT(state, payload) {
      state.activeObject = payload;
    },
    SET_HAS_SHOP_STORE(state, payload) {
      state.hasShopStore = payload;
    },
    SET_HAS_CARD(state, payload) {
      state.hasCard = payload;
    },
    SET_ELECTRON_CARD_TYPE(state, payload) {
      state.electronCardType = payload;
    },
    SET_FRONT_CARD(state, payload) {
      state.frontCard = payload;
    },
    SET_BACK_CARD(state, payload) {
      state.backCard = payload;
    },
  },
};

export default canvas;
