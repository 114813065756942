import { MarkdownEditor } from "@/views/markdown/editor/utils/node";
import { throttle } from "@/views/markdown/editor/utils/common";
import { SlateEditor, SlateTransforms } from "@wangeditor/editor";

const CHANGE_LIMIT_OP = [
  "insert_text",
  "remove_text",
  "set_node",
  "insert_node",
  "remove_node",
  "merge_node",
  "split_node",
  "move_node",
];

const changeNodeState = throttle(() => {
  MarkdownEditor.emit("changeNodeState");
}, 200);

const editorPlugin = (editor) => {
  const e = editor;
  const { apply } = e;

  e.apply = (op) => {
    if (CHANGE_LIMIT_OP.includes(op.type)) {
      changeNodeState();
    }

    apply(op);
  };

  e.clear = () => {
    const initialEditorValue = [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ]

    SlateTransforms.delete(e, {
      at: {
        anchor: SlateEditor.start(e, []),
        focus: SlateEditor.end(e, []),
      },
    })

    const isWithoutEmptyWithListItem = e.children.length === 1 && e.children[0].type === 'list-item'

    if (e.children.length === 0) {
      SlateTransforms.insertNodes(e, initialEditorValue)
    } else if (isWithoutEmptyWithListItem) {
      SlateTransforms.removeNodes(e, { at: [0] });
      SlateTransforms.insertNodes(e, initialEditorValue)
    }
  }

  return e;
};

export { editorPlugin };
