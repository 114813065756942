import {
  header1ToHtmlConf,
  header2ToHtmlConf,
  header3ToHtmlConf,
  header4ToHtmlConf,
  header5ToHtmlConf,
} from "./elem-to-html";
import {
  parseHeader1HtmlConf,
  parseHeader2HtmlConf,
  parseHeader3HtmlConf,
  parseHeader4HtmlConf,
  parseHeader5HtmlConf,
} from "./parse-elem-html";
import {
  renderHeader1Conf,
  renderHeader2Conf,
  renderHeader3Conf,
  renderHeader4Conf,
  renderHeader5Conf,
} from './render-elem'
export { OPTIONS } from './constants'

const header = {
  elemsToHtml: [
    header1ToHtmlConf,
    header2ToHtmlConf,
    header3ToHtmlConf,
    header4ToHtmlConf,
    header5ToHtmlConf,
  ],
  parseElemsHtml: [
    parseHeader1HtmlConf,
    parseHeader2HtmlConf,
    parseHeader3HtmlConf,
    parseHeader4HtmlConf,
    parseHeader5HtmlConf,
  ],
  renderElems: [
    renderHeader1Conf,
    renderHeader2Conf,
    renderHeader3Conf,
    renderHeader4Conf,
    renderHeader5Conf,
  ],
};

export default header;
