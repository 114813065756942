<template>
  <div id="app">
    <!-- 小程序跳转二维码 -->
		<miniProgramCode />
    <keep-alive :include="['toolbar', 'editIndex']">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import miniProgramCode from "@/components/miniProgramCode/miniProgramCode.vue";

export default{
  components: {
    miniProgramCode,
  },
}

</script>

<style lang="scss" scoped>
@supports (bottom: env(safe-area-inset-bottom)) {
  body,
  .footer {
    box-sizing: content-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

@media screen and (max-width: 750px) {
  #app {
    // 隐藏滚动条
    height: 100vh;
    &::-webkit-scrollbar {
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    overflow-x: hidden;
    overflow-y: scroll;
    margin-top: -16px;
    .container {
      width: 1200px;
      margin: 30px auto;
    }
  }
}
</style>
