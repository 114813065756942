import Vue from 'vue'
import Vuex from 'vuex'
import markdown from './modules/markdown'
import user from './modules/user'
import canvas from './modules/canvas'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    list: [],
    index: -1,
    AIAid: 'https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/miniapps/wqzk/pages_AIAid/',
    wqUrl: 'https://payment-writemall-com.oss-cn-shenzhen.aliyuncs.com/miniapps/wqzk/',
    fileUrl: "https://fafs.antiplagiarize.com/",
    // 选区内容
    selectionContent: '',
    socketInfo: {}, // ws监听数据
    // 复制类型 1 剪切 0 复制
    Calltype: 0,
    buyDialogText: {
			title: '开通会员',
			content: '当前次数已经用完,开通VIP可获取更多次数',
			vipText: "30次/日",
			isRecharge: true,
			isVip: false,
			isPay: false, 
			payText: "积分不足"
		},
    bugDialog: false,
  },
  getters: {
  },
  mutations: {
    setsocketInfo(state, payload) {
      state.socketInfo = payload
    },
    setBuyDialogText(state, payload) {
			state.buyDialogText = payload
		},
    setBugDialog(state, payload) {
			state.bugDialog = payload
		},
    setIndex(state, val) {
      state.index = val
    },
    setSelectionContent(state, val) {
      state.selectionContent = val
    },
    setCalltype(state, val) {
      state.Calltype = val
    },
  },
  actions: {
  },
  modules: {
    markdown,
    user,
    canvas
  }
})
