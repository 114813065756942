export async function sleep(ms, ping) {
  if (typeof ping === "function" && ms > 1000) {
    const timestamps = new Array(Math.floor(ms / 1000)).fill(0);

    for (const time of timestamps) {
      await sleep(time);
      ping();
    }
  } else {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function throttle(func, wait, leading, trailing) {
  let timeout, context, args, result;
  let previous = 0;
  const later = function () {
    previous = leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    const now = Date.now();
    if (!previous && leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

export function cloneDeep(obj, hash = new WeakMap()) {
  if (Object(obj) !== obj) return obj;
  if (obj instanceof Set) return new Set(obj);
  if (obj instanceof Map) return new Map(obj);
  if (obj instanceof Date) return new Date(obj);
  if (obj instanceof RegExp) return new RegExp(obj);
  if (hash.has(obj)) return hash.get(obj);

  const allDesc = Object.getOwnPropertyDescriptors(obj);
  const cloneObj = Object.create(Object.getPrototypeOf(obj), allDesc);

  hash.set(obj, cloneObj);

  Reflect.ownKeys(allDesc).forEach(key => {
    allDesc[key].value = cloneDeep(allDesc[key].value, hash);
  });

  return cloneObj;
}

export function uniqueBy(array, iteratee) {
  const seen = new Set();
  return array.filter(item => {
    const value = typeof iteratee === 'function' ? iteratee(item) : item[iteratee];
    if (seen.has(value)) {
      return false;
    }
    seen.add(value);
    return true;
  });
}
