import { SlateText } from "@wangeditor/editor";
import $ from "dom7";

function genParser(level) {
  function parseHtml(elem, children, editor) {
    const $elem = $(elem);


    children = children.filter((child) => {
      if (SlateText.isText(child)) return true;
      if (editor.isInline(child)) return true;
      return false;
    });

    // 无 children ，则用纯文本
    if (children.length === 0) {
      children = [{ text: $elem.text().replace(/\s+/gm, " ") }];
    }

    const headerNode = {
      type: `header${level}`,
      children,
    };

    return headerNode;
  }
  return parseHtml;
}

const getSelector = (level) => {
  return `h${level}[data-w-e-type="header${level}"]`;
};

export const parseHeader1HtmlConf = {
  selector: getSelector(1),
  parseElemHtml: genParser(1),
};

export const parseHeader2HtmlConf = {
  selector: getSelector(2),
  parseElemHtml: genParser(2),
};

export const parseHeader3HtmlConf = {
  selector: getSelector(3),
  parseElemHtml: genParser(3),
};

export const parseHeader4HtmlConf = {
  selector: getSelector(4),
  parseElemHtml: genParser(4),
};

export const parseHeader5HtmlConf = {
  selector: getSelector(5),
  parseElemHtml: genParser(5),
};
