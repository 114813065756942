import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "assets/iconfont/iconfont.css";
import "assets/scss/index.scss";
import "./plugins/vant";
import { Toast } from "vant";
import "jweixin-module";
import Element from 'element-ui'
import 'github-markdown-css';

// import Vconsole from 'vconsole'

// new Vconsole()

Vue.config.productionTip = false;
Vue.prototype.$onCopy = (val, text = "复制成功", Keyboard) => {
  var input = document.createElement("input");
  input.value = val;
  document.body.appendChild(input);
  input.select();
  window.frames?.[0]?.L?.Map?.THIS?.focus(Keyboard);
  document.execCommand("Copy");
  document.body.removeChild(input);
  // Toast({
  //   message: text,
  //   position: "top",
  // });
  if (window.innerWidth < 864) {
    Toast({
      message: text,
      position: 'top',
    });
  } else {
    Vue.prototype.$message.success(text)
  }
};
// 全局eventbus
let EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus;
    },
  },
});

Vue.use(Element)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
